<template>
  <div>
    <div>
      <AlertErrors :form="filters" />

      <b-form @submit.prevent="fetchAnalytics()" inline>
        <b-input-group size="sm" class="mr-sm-3 mb-sm-2">
          <template #prepend>
            <b-input-group-text class="text-muted">
              Creation From Date
            </b-input-group-text>
          </template>

          <b-form-input
            v-model="filters.creation_from_date"
            type="date"
            :state="filters.errors.has('creation_from_date') ? false : null"
            :required="filters.creation_to_date != ''"
          ></b-form-input>
        </b-input-group>

        <b-input-group size="sm" class="mr-sm-3 mb-sm-2">
          <template #prepend>
            <b-input-group-text class="text-muted">
              Creation To Date
            </b-input-group-text>
          </template>

          <b-form-input
            v-model="filters.creation_to_date"
            type="date"
            :state="filters.errors.has('creation_to_date') ? false : null"
          ></b-form-input>
        </b-input-group>

        <b-input-group size="sm" class="mr-sm-3 mb-sm-2">
          <template #prepend>
            <b-input-group-text class="text-muted">
              Overdue Payment Hour
            </b-input-group-text>
          </template>

          <b-form-input
            v-model="filters.overdue_payment_hour"
            type="number"
            placeholder="Default: 1 hour"
            :state="filters.errors.has('overdue_payment_hour') ? false : null"
          ></b-form-input>
        </b-input-group>

        <b-input-group size="sm" class="mr-sm-3 mb-sm-2">
          <template #prepend>
            <b-input-group-text class="text-muted">
              Overdue Delivery Hour
            </b-input-group-text>
          </template>

          <b-form-input
            v-model="filters.overdue_delivery_hour"
            type="number"
            placeholder="Default: 1 hour"
            :state="filters.errors.has('overdue_delivery_hour') ? false : null"
          ></b-form-input>
        </b-input-group>

        <b-button type="submit" variant="primary" block>Analyse</b-button>
      </b-form>
    </div>

    <div class="mt-5">
      <b-skeleton-wrapper :loading="loading">
        <template #loading>
          <b-skeleton width="40%" height="50px"></b-skeleton>
        </template>

        <h1 class="text-dark">
          Medical Requests Count:
          {{ analytics ? analytics[0].prescriptions_count : 0 }}
        </h1>
      </b-skeleton-wrapper>
    </div>

    <!-- Payment & Delivery -->
    <b-row class="mt-5">
      <b-col>
        <div class="text-center border-bottom mb-3">
          <h6 class="font-weight-bold mb-0">Payment Status</h6>
          <small>
            Showing the different payment statuses and their percentages
          </small>
        </div>

        <b-skeleton-wrapper :loading="loading">
          <template #loading>
            <b-skeleton-img></b-skeleton-img>
          </template>

          <apexchart
            type="pie"
            :options="paymentStatusChartOptions"
            :series="paymentStatusChartSeries"
          ></apexchart>
        </b-skeleton-wrapper>
      </b-col>

      <b-col>
        <div class="text-center border-bottom mb-3">
          <h6 class="font-weight-bold mb-0">Delivery Status</h6>
          <small>
            Showing the different delivery statuses and their percentages
          </small>
        </div>

        <b-skeleton-wrapper :loading="loading">
          <template #loading>
            <b-skeleton-img></b-skeleton-img>
          </template>

          <apexchart
            type="pie"
            :options="deliveryStatusChartOptions"
            :series="deliveryStatusChartSeries"
          ></apexchart>
        </b-skeleton-wrapper>
      </b-col>
    </b-row>

    <!-- Overdues -->
    <b-row class="mt-5">
      <b-col>
        <div class="text-center border-bottom mb-3">
          <h6 class="font-weight-bold mb-0">Overdue Payment</h6>
          <small>
            Showing the total number of medical requests vs the overdue payments
            within
            {{
              filters.overdue_payment_hour ? filters.overdue_payment_hour : 1
            }}
            hour{{
              (filters.overdue_payment_hour
                ? filters.overdue_payment_hour
                : 1) > 1
                ? "s"
                : ""
            }}
          </small>
        </div>

        <b-skeleton-wrapper :loading="loading">
          <template #loading>
            <b-skeleton-img></b-skeleton-img>
          </template>

          <apexchart
            type="pie"
            :options="overduePaymentChartOptions"
            :series="overduePaymentChartSeries"
          ></apexchart>
        </b-skeleton-wrapper>
      </b-col>

      <b-col>
        <div class="text-center border-bottom mb-3">
          <h6 class="font-weight-bold mb-0">Overdue Delivery</h6>
          <small>
            Showing the total number of medical requests vs the overdue
            deliveries within
            {{
              filters.overdue_delivery_hour ? filters.overdue_delivery_hour : 1
            }}
            hour{{
              (filters.overdue_delivery_hour
                ? filters.overdue_delivery_hour
                : 1) > 1
                ? "s"
                : ""
            }}
          </small>
        </div>

        <b-skeleton-wrapper :loading="loading">
          <template #loading>
            <b-skeleton-img></b-skeleton-img>
          </template>

          <apexchart
            type="pie"
            :options="overdueDeliveryChartOptions"
            :series="overdueDeliveryChartSeries"
          ></apexchart>
        </b-skeleton-wrapper>
      </b-col>
    </b-row>

    <!-- Calls -->
    <b-row class="mt-5">
      <b-col>
        <div class="text-center border-bottom mb-3">
          <h6 class="font-weight-bold mb-0">Calls for Medical Requests</h6>
          <small>
            Showing the medical requests with calls and their percentages.
          </small>
        </div>

        <b-skeleton-wrapper :loading="loading">
          <template #loading>
            <b-skeleton-img></b-skeleton-img>
          </template>

          <apexchart
            type="pie"
            :options="callsChartOptions"
            :series="callsChartSeries"
          ></apexchart>
        </b-skeleton-wrapper>
      </b-col>
      <b-col></b-col>
    </b-row>
  </div>
</template>

<script>
import { endpoints } from "@/utils/endpoints";
import VueApexCharts from "vue-apexcharts";

export default {
  name: "MedicalRequestsAnalytics",
  components: {
    apexchart: VueApexCharts,
  },
  data() {
    return {
      loading: true,
      filters: new this.$Form({
        creation_from_date: "",
        creation_to_date: "",
        overdue_payment_hour: "",
        overdue_delivery_hour: "",
      }),
      analytics: null,
      simplePieChartOptions: {
        chart: {
          width: "100%",
          type: "pie",
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },
      simpleRadialBarChartOptions: {
        chart: {
          height: 350,
          type: "radialBar",
        },
        plotOptions: {
          radialBar: {
            hollow: {
              size: "70%",
            },
          },
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                show: false,
              },
            },
          },
        ],
      },
    };
  },
  computed: {
    paymentStatusChartOptions() {
      let chart = JSON.parse(JSON.stringify(this.simplePieChartOptions));

      chart.labels = ["Paid", "Paid Not Confirmed", "Not Paid"];

      chart.colors = ["#008ffb", "#FF9800", "#ff000d"];

      return chart;
    },
    paymentStatusChartSeries() {
      if (!this.analytics) {
        return [0, 0, 0];
      }

      return [
        this.analytics[0].prescriptions_paid,
        this.analytics[0].prescriptions_paid_not_confirmed,
        this.analytics[0].prescriptions_not_paid,
      ];
    },
    deliveryStatusChartOptions() {
      let chart = JSON.parse(JSON.stringify(this.simplePieChartOptions));

      chart.labels = ["Delivered", "Not Delivered"];

      chart.colors = ["#00c292", "#FF9800"];

      return chart;
    },
    deliveryStatusChartSeries() {
      if (!this.analytics) {
        return [0, 0];
      }

      return [
        this.analytics[0].prescriptions_delivered,
        this.analytics[0].prescriptions_not_delivered,
      ];
    },
    overduePaymentChartOptions() {
      let chart = JSON.parse(JSON.stringify(this.simplePieChartOptions));

      chart.labels = ["On-time", "Overdue"];

      chart.colors = ["#008ffb", "#FF9800"];

      return chart;
    },
    overduePaymentChartSeries() {
      if (!this.analytics) {
        return [0];
      }

      return [
        this.analytics[0].prescriptions_count -
          this.analytics[0].prescriptions_overdue_payment,
        this.analytics[0].prescriptions_overdue_payment,
      ];
    },
    overdueDeliveryChartOptions() {
      let chart = JSON.parse(JSON.stringify(this.simplePieChartOptions));

      chart.labels = ["On-time", "Overdue"];

      chart.colors = ["#008ffb", "#FF9800"];

      return chart;
    },
    overdueDeliveryChartSeries() {
      if (!this.analytics) {
        return [0];
      }

      return [
        this.analytics[0].prescriptions_count -
          this.analytics[0].prescriptions_overdue_delivery,
        this.analytics[0].prescriptions_overdue_delivery,
      ];
    },
    callsChartOptions() {
      let chart = JSON.parse(JSON.stringify(this.simplePieChartOptions));

      chart.labels = [
        "Pending",
        "Proceed",
        "Proceed with wallet",
        "Declined",
        "Sponsor will handle",
        "Exceeded",
      ];

      chart.colors = [
        "#1ab7ea",
        "#0084ff",
        "#39539E",
        "#ff000d",
        "#000000",
        "#FF9800",
      ];

      return chart;
    },
    callsChartSeries() {
      if (!this.analytics) {
        return [0, 0, 0, 0, 0, 0];
      }

      return [
        this.analytics[0].prescriptions_with_pending_calls,
        this.analytics[0].prescriptions_with_proceed_calls,
        this.analytics[0].prescriptions_with_proceedpayfromwallet_calls,
        this.analytics[0].prescriptions_with_declined_calls,
        this.analytics[0].prescriptions_with_sponsor_will_handle_calls,
        this.analytics[0].prescriptions_with_exceeded_calls,
      ];
    },
  },
  methods: {
    fetchAnalytics() {
      this.loading = true;

      this.filters
        .get(endpoints.GET_MEDICAL_REQUESTS_ANALYTICS)
        .then((response) => {
          this.analytics = response;
        })
        .catch((error) => {
          this.$toast.error(
            error.response?.data?.message || error.response?.message
          );
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    this.fetchAnalytics();
  },
};
</script>
